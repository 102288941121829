import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, DataTable, SecondaryCallout, PageSubSection } from '../../../components';
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';
import { StaticImage } from 'gatsby-plugin-image';
import a18CervicalTable from "../../../assets/a-18-cervical-v5-mobile.svg"

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-A18: Selected Safety Data and Adverse Reactions`,
    keywords: `keytruda combination chemoradiotherapy cervical cancer adverse reactions`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the selected safety data associated with KEYNOTE-A18.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cervical-cancer-combination-chemoradiotherapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with chemoradiotherapy (CRT), is indicated for the treatment of patients with FIGO 2014 Stage III-IVA cervical cancer."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cervical-cancer-combination-chemoradiotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

export const a18CervicalTableAlt = "Adverse Events Occurring in ≥10% of Patients With FIGO 2014 Stage 3-4A Cervical Cancer Receiving KEYTRUDA® (pembrolizumab) in KEYNOTE-A18"

//from /src/util/data/indications.js
// UPDATE THIS TO THE CORRECT INDICATION #
const indicationId = 37; 
const jobCode = jobCodes[24].jobCode; 

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy Persistent, Recurrent, or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-first-line-combination-therapy/' },
                { text: '2L Monotherapy Recurrent or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-second-line-monotherapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Selected Safety Data from KEYNOTE-A18' }
];

const AeFigoFootnotes = [
    {
        label: 'c.',
        text: 'Graded per NCI CTCAE v5.0.'
    },
    {
        label: 'd.',
        text: 'Includes urinary tract infection, urinary tract infection pseudomonal, pyelonephritis acute, cystitis, Escherichia urinary tract infection.'
    },
    {
        label: 'e.',
        text: 'Includes fatigue, asthenia.'
    },
    {
        label: 'f.',
        text: 'Includes hypothyroidism, autoimmune hypothyroidism.'
    },
    {
        label: 'g.',
        text: 'Includes erythema multiforme, dermatitis, drug eruption, eczema, rash, skin exfoliation, dermatitis bullous, rash maculo-papular, lichen planus, dyshidrotic eczema, dermatitis acneiform. '
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection 
                            title={anchorLinkModelData[2].label}
                        >
                            <TextBlock definitions="COVID-19 = coronavirus disease 2019; ALT = alanine aminotransferase; SARS-CoV-2 = severe acute respiratory syndrome corona virus 2.">
                                <p>The safety of KEYTRUDA in combination with CRT (cisplatin plus EBRT followed by BT) was evaluated in 594 patients with FIGO 2014 Stage III-IVA cervical cancer in KEYNOTE⁠-⁠A18, which included 292 patients who received KEYTRUDA in combination with chemoradiotherapy and 302 patients who received placebo in combination with chemoradiotherapy.</p>
                                <p>The median duration of exposure to KEYTRUDA was 12.1 months (range: 1 day to 27 months). </p>
                                <p>Fatal adverse reactions occurred in 1.4% of patients receiving KEYTRUDA + CRT, including 1 case each (0.3%) of:</p>
                                <ul>
                                    <li>Large intestinal perforation</li>
                                    <li>Urosepsis</li>
                                    <li>Sepsis</li>
                                    <li>Vaginal hemorrhage</li>
                                </ul>
                                <p>Serious adverse reactions occurred in 30% of patients receiving KEYTRUDA + CRT. Serious adverse reactions occurring in ≥1% of patients included: </p>
                                <ul>
                                    <li>Urinary tract infection (2.7%)</li>
                                    <li>Urosepsis (1.4%)</li>
                                    <li>Sepsis (1%)</li>
                                </ul>
                                <p>KEYTRUDA was discontinued for adverse reactions in 7% of patients. The most common adverse reaction (≥1%) resulting in permanent discontinuation was:</p>
                                <ul>
                                    <li>Diarrhea (1%)</li>
                                </ul>
                                <p>Adverse reactions leading to interruption of KEYTRUDA occurred in 43% of patients. The most common adverse reactions leading to interruption of KEYTRUDA (≥2%) were:</p>
                                <ul>
                                    <li>Anemia (8%)</li>
                                    <li>COVID-19 (6%)</li>
                                    <li>SARS-CoV-2 test positive (3.1%)</li>
                                    <li>Decreased neutrophil count (2.7%)</li>
                                    <li>Diarrhea (2.7%) </li>
                                    <li>Urinary tract infection (2.7%)</li>
                                    <li>Increased ALT (2.4%)</li>
                                </ul>
                            </TextBlock>
                            <DataTable
                                title="Adverse Events Occurring in ≥10% of Patients With FIGO 2014 Stage III-IVA Cervical Cancer Receiving KEYTRUDA in KEYNOTE&#8288;-&#8288;A18"
                                mobileImage={() => <img src={a18CervicalTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={a18CervicalTableAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/a-18-cervical-v5.png"  alt={a18CervicalTableAlt}/>}
                                footnotes={AeFigoFootnotes}
                                definitions="NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events; Q3W = every 3 weeks; Q6W = every 6 weeks."
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}
                    
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
